import React, {Component} from 'react';
import './UserAdministration.css';

//Components
import MenuItem from '@mui/material/MenuItem';
import ToolButton from "../../../../../reusable/ToolButton/ToolButton";
import TextField from "@mui/material/TextField";
import ReactTooltip from "react-tooltip";
import ListItem from "../../../../../reusable/ListItem/ListItem";
import UserDialog from "./UserDialog/UserDialog";
import ToolFab from "../../../../../reusable/ToolFab/ToolFab";
import AddIcon from "@mui/icons-material/Add";
import api from "../../../../../api";
import YesCancelDialog from "../../../../../reusable/YesCancelDialog/YesCancelDialog";

class UserAdministration extends Component {

    state = {
        activeUser:null,
        shownUsers: [],
        searchWord:'',
        searchType:'username',
        selectedItem:null,
        action:null,
        isNewItem:true,
        formattedItems:[],
        isSaved:true,

        //Dialogs
        showUserDialog:false
    }

    searchWordOnChange = (e) => {
        this.setState({searchWord:e.target.value});
    }

    searchTypeOnChange = (e) => {
        this.setState({searchType:e.target.value});
    }

    searchOnClick = () => {
        if(this.state.searchType !== null) {
            let shownUsers = [];
            switch (this.state.searchType) {
                case "username":
                    this.props.users.forEach(element => {
                        if(element.username && element.username.toLowerCase().includes(this.state.searchWord.toLowerCase())) {
                            shownUsers.push(element);
                        }
                    });
                    break;
                default:
                    break;
            }
            this.setState({shownUsers:shownUsers});
        }else{
            this.props.showMessage(2,'Bitte wählen Sie zunächst eine Suche aus');
        }
    }

    //User on Change Handler

    usernameOnChangeHandler = (e) => {
        let user = this.state.activeUser;
        user.username = e.target.value;
        this.setState({activeUser:user});
    }

    passwordOnChangeHandler = (e) => {
        let user = this.state.activeUser;
        user.password = e.target.value;
        this.setState({activeUser:user});
    }

    roleOnChangeHandler = (e) => {
        let user = this.state.activeUser;
        user.role = e.target.value;
        this.setState({activeUser:user});
    }

    defaultStationOnChangeHandler = (stationId) => {
        console.log(stationId);
        let user = this.state.activeUser;
        if(stationId) {
            for(let i = 0; i < this.props.stations.length; i++) {
                if(stationId === this.props.stations[i].id) {
                    user.defaultStation = this.props.stations[i];
                    break;
                }
            }
        }else{
            user.defaultStation = null;
        }
        this.setState({activeUser:user});
    }

    setIsSaved = (isSaved) => {
        this.setState({isSaved:isSaved});
    }

    createNewUser = (user) => {
        const url = '/users';
        api.post(url,user)
            .then(response => {
                this.props.addUserToList(response.data);
                this.props.showMessage(0,"Der Benutzer wurde erfolgreich angelegt");
                this.searchOnClick();
                this.closeUserDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    updateUser = (user) => {
        const url = '/users';
        api.put(url,user)
            .then(response => {
                this.props.updateUserInList(response.data);
                this.props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                this.searchOnClick();
                this.closeUserDialog();
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    deleteUser = (user) => {
        const url = '/users';
        api.delete(url + "/" + user.id)
            .then(response => {
                this.props.removeUserFromList(user);
                this.props.showMessage(0,"Der Benutzer wurde erfolgreich gelöscht");
                this.searchOnClick();
                this.closeUserDialog();
                this.setState({activeUser:null});
            })
            .catch(error => {
                if(error.response) {
                    this.props.showMessage(2,error.response.data.message);
                } else if (error.request) {
                    this.props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    console.log(error.request);
                } else {
                    this.props.showMessage(2,"Etwas ist schiefgelaufen");
                    console.log(error.message);
                }
            });
    }

    //Dialog actions

    showUserDialog = (user) => {
        this.setState({activeUser:user,showUserDialog:true});
    }

    closeUserDialog = () => {
        this.setState({showUserDialog:false});
    }

    showDeleteUserDialog = (user) => {
        this.setState({activeUser:user,showDeleteUserDialog:true})
    }

    closeDeleteUserDialog = () => {
        this.setState({showDeleteUserDialog:false})
    }

    render() {

        let blankUser = {
            username:"",
            password:"",
            role:"USER"
        }

        return (
            <div className='user-administration'>
                <ReactTooltip/>

                <UserDialog
                    open={this.state.showUserDialog}
                    close={this.closeUserDialog}
                    showMessage={this.props.showMessage}
                    currentUser={this.props.user}
                    user={this.state.activeUser}
                    updateUser={this.updateUser}
                    updateUserInList={this.props.updateUserInList}
                    createNewUser={this.createNewUser}
                    approveUser={this.approveUser}
                    stations={this.props.stations}

                    //File
                    displayEmployeeFile={this.displayEmployeeFile}

                    //On Change Handler
                    usernameOnChangeHandler={this.usernameOnChangeHandler}
                    passwordOnChangeHandler={this.passwordOnChangeHandler}
                    roleOnChangeHandler={this.roleOnChangeHandler}
                    defaultStationOnChangeHandler={this.defaultStationOnChangeHandler}
                />

                <YesCancelDialog
                    open={this.state.showDeleteUserDialog}
                    close={this.closeDeleteUserDialog}
                    header='Benutzer Löschen'
                    text='Wollen Sie den Benutzer wirklich löschen? Sie können die Aktion nicht rückgängig machen.'
                    onClick={() => this.deleteUser(this.state.activeUser)}
                />

                <div className='header-bar'>
                    <h1>Benutzerverwaltung</h1>
                    <TextField text='Suchwort' value={this.state.searchWord} onChange={this.searchWordOnChange} className='search-input' label='Suchwort' size='small'/>
                    <TextField value={this.state.searchType} onChange={this.searchTypeOnChange} size='small' select className='search-select' label='Suche'>
                        <MenuItem key={0} value={'username'}>Benutzername</MenuItem>
                    </TextField>
                    <ToolButton main className='search-button' onClick={this.searchOnClick}>
                        Suche
                    </ToolButton>
                </div>
                <div className='item-list'>
                    {this.state.shownUsers.map((user,index) => (
                        <ListItem
                            key={index}
                            type="user"
                            item={user}
                            currentUser={this.props.user}
                            showItemDialog={() => this.showUserDialog(user)}
                            showDeleteItemDialog={() => this.showDeleteUserDialog(user)}
                        />
                    ))}
                </div>
                {this.props.user && this.props.user.role === "ADMIN" ?
                    <ToolFab className='add-fab' onClick={() => this.showUserDialog(blankUser)} aria-label="add">
                        <AddIcon/>
                    </ToolFab> : null}
            </div>
        )
    }
}

export default UserAdministration;