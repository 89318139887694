import React, {Component} from "react";
import './Administration.css';
import {Route} from "react-router-dom";

//Components
import UserAdministration from "./UserAdministration/UserAdministration";
import CompanyAdministration from "./CompanyAdministration/CompanyAdministration";

class Administration extends Component {
    render() {
        return (
            <div className='administration'>
                <Route exact path='/administration'>
                    <UserAdministration
                        showMessage={this.props.showMessage}
                        stations={this.props.stations}

                        //Users
                        user={this.props.user}
                        users={this.props.users}
                        addNewUser={this.props.addNewUser}
                        updateUserInList={this.props.updateUserInList}
                        addUserToList={this.props.addUserToList}
                        removeUserFromList={this.props.removeUserFromList}
                    />
                </Route>
                <Route exact path='/administration/company'>
                    <CompanyAdministration
                        showMessage={this.props.showMessage}
                        countries={this.props.countries}

                        //Company
                        company={this.props.company}
                        updateCompanyInformationInState={this.props.updateCompanyInformationInState}
                    />
                </Route>
            </div>
        )
    }
}

export default Administration;