import React, {Component} from "react";
import './UserMenu.css';

//Components
import LogoutIcon from '@mui/icons-material/Logout';
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

class UserMenu extends Component {

    state = {
        showMenuPanel:false
    }

    showSettings = () => {
        this.toggleMenuPanel();
        this.props.showSettings();
    }

    toggleMenuPanel = () => {
        this.setState({showMenuPanel:!this.state.showMenuPanel});
    }

    render() {

        return (
            <div className='user-menu'>
                <ul className='holder'>
                    <li>
                        {this.props.company && this.props.company.rights === 2 ? <TextField value={this.props.station ? this.props.station.id : null} onChange={(e) => this.props.stationOnChangeHandler(e.target.value)} select className='search-select' label='ausgewählte Station' InputLabelProps={{shrink: true}}>
                            <MenuItem key={0} value={null}>
                                Keine
                            </MenuItem>
                            {this.props.stations.map((station) => (
                                <MenuItem key={station.id} value={station.id}>
                                    {station.name}
                                </MenuItem>
                            ))}
                        </TextField> : null}
                    </li>
                    <li>
                        <h2 onClick={this.toggleMenuPanel}>{this.props.user ? this.props.user.username : 'Lädt...'}</h2>
                    </li>
                    <li>
                        <LogoutIcon className='icon' onClick={this.props.logout}/>
                    </li>
                </ul>
            </div>
        )
    }
}

export default UserMenu;